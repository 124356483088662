.sign-in-container {
  margin: 100px auto;
  max-width: 400px;
  alignment: center;
}

.bottom-divider {
  border-bottom: 1px solid #eaeded;
}

.left-divider {
  border-left: 1px solid #eaeded;
}

.right-divider {
  border-right: 1px solid #eaeded;
}

.standard-border {
  border: 1px solid #aab7b8;
}
