html {
  font-size: 0.625rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.full-height-card {
  height: 100%;
}
